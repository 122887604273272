<template>
    <div class="result">
        <div class="result-main">
            <div class="result-title">
                <h2>START领前列·竞巅峰-2022贝坦利优秀病例大赛</h2>
                <i>{{ title }}</i>
                <h3>优胜选手</h3>
            </div>
            <div class="result-head">
                <div class="result-carry" v-for="(item,index) in list">
                    <div class="carry-head">
                        <img :src="item.player_img || 'https://image.ysxxlm.cn/ysxxlm/pc/static/img/my.png'"/>
                    </div>
                    <div class="carry-name">{{ item.player_name }}<p>{{ item.player_hospital_name }}</p></div>
                    <div class="pwpf-info">
                        <div class="pwpf-info-item">
                            <p>评委评分:{{ item.judges_score }}</p>
<!--                            <p>推文得分： {{ item.tweet_number }}</p>-->
<!--                            <p>现场直播得分:{{ item.vote_score }}</p>-->
                            <!-- <p>手术部分得分:{{ item.judges_score1 }}</p> -->
                            <p>观众评分:{{ item.vote_score }}</p>
                            <!-- <p>病例部分得分:{{ item.judges_score2 }}</p> -->
                            <p>抢答得分:{{ item.Answerscore }}</p>
                            <p>最终得分:{{ item.allscore }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                title: '',
                list: []
            }
        },
        computed:{
            arr() {}
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                this.$axios.post(this.apiUrl.byresult_self).then(res => {
                    this.list = res.data.array_data
                    this.title = res.data.group_name
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    .result{
        background: url("~@/assets/img/by2022-9.jpg") no-repeat;
        background-size: 100% 100%;
        min-height: 100vh;
        .result-main{/*
            background: rgba(000,000,000,.3);*/
            /*position: fixed;*/
            /*left: 0;*/
            /*top: 0;*/
            /*right: 0;*/
            /*bottom: 0;*/
            text-align: center;
			padding-top: 1px;
            .result-title{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                font-weight: bold;
                font-family:SimHei;
				color: #ffc741;
				margin-top: 90px;
				h2{font-size: 50px;line-height: 60px;}
				i{font-size: 45px;font-family:'楷体'; font-style:normal;line-height: 55px;}
                h3{
					width: 335px;
                    font-size: 40px;
                    text-align: center;
                    text-transform:uppercase;
                    color: #ffc741;
                    letter-spacing:3px;
					background: #0a93ef;
					line-height: 70px;
					border-radius: 70px;
					margin-top: 15px;
                }
            }
            .result-head{
                margin-top: 10px;
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
                .result-carry{
                    vertical-align: top;
                    margin: 0 2%;
                }
            }
            .result-carry{
                display: inline-block;
                box-sizing: border-box;
                position: relative;
                .carry-ranking{
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    width: 50px;
                    height: 50px;
                }
                .carry-head{
                    width: 232px;
                    height: 232px;
					border: #65afd5 3px solid;
					border-radius: 50%;
                    // background: url("https://image.ysxxlm.cn/ysxxlm/pc/static/img/tximg.png") no-repeat;
                    // background-size: 100% 100%;
                    padding: 3px;
                    margin: 0 auto;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                    }
                }
                .carry-name{
                    text-align: center;
                    font-size: 38px;
                    margin-bottom: 10px;
					color: #ffc741;
                    margin-top: 10px;
					line-height: 43px;
					p{font-size: 30px;}
                }
            }
        }
    }
    .pwpf-info{
        text-align: left;
        .pwpf-info-item{
            padding: 5px 0;
            font-size: 24px;
			color: #fff;
        }

        p{margin: 0}
    }

</style>
